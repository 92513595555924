import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Button, Dropdown, Form} from "react-bootstrap";
import VerboseDiagram from "../cyDiagram/VerboseDiagram";
import {getConnectionsDiagramElements} from "../cyDiagram/dataTransforms";
import ConnectionsDiagramData from "../../types/connectionsDiagram.type";
import {ErrorBoundary} from "react-error-boundary";

type Props = {
    data: ConnectionsDiagramData;
}

const ConnectionsDiagram = (props: Props) => {
    const style = {
        width: "100%",
        height: "100%",
        marginTop: '1.5rem',
        padding: '1rem',
        borderTop: "1px lightgray solid",
    };

    const REGRESSION_THRESHOLD = 0.1;
    const layoutKeys = ['preset', 'fcose', 'cose-bilkent', 'cola', 'dagre', 'elk_box', 'elk_disco', 'elk_force', 'elk_mrtree', 'elk_stress'];
    const {data} = {...props};

    const [showCorrelation, setShowCorrelation] = useState<boolean>(false);
    const [edgeWidthCoefficient, setEdgeWidthCoefficient] = useState<number>(10);
    const [nodesLimit, updateNodesLimit] = useState<number>(20);
    const [elements, setElements] = useState<{}[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [layoutName, setLayoutName] = useState('fcose');
    const [loading, setLoading] = useState<boolean>(true);
    const [pageHeight, setPageHeight] = useState<number>(0);
    const [containerWidth, setContainerWidth] = useState<number>(0);

    const containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const updateSize = () => {
            setPageHeight(window.innerHeight);
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        const newElements = getConnectionsDiagramElements(data, REGRESSION_THRESHOLD, containerWidth, sizeToUse);
        setElements(newElements);
    }, [nodesLimit]);

    useEffect(() => {
        const slider = document.getElementById('connectionsEdgeWidthSlider');
        if (!slider) return;
        const handleInput = (event: any) => setEdgeWidthCoefficient(event.target.value);
        slider.addEventListener('input', handleInput);
        return () => slider.removeEventListener('input', handleInput);
    }, []);

    const minHeight = 600;
    const sizeToUse = Math.max(minHeight, pageHeight);

    useEffect(() => {
        if (elements && sizeToUse !== 0 && pageHeight !== 0) {
            setLoading(false);
        }
        console.log("Container Height: ", sizeToUse);
        console.log("Container Width: ", containerWidth);
    }, [elements, sizeToUse, pageHeight, containerWidth, edgeWidthCoefficient]);

    return (
        <div className="cyContainer d-flex justify-content-between flex-column"
             style={{height: sizeToUse + 'px'}} ref={containerRef}>
            <div className="cyControls d-flex flex-column flex-sm-row" style={{maxWidth: '80%'}}>
                <Dropdown className={"dropdown pe-4"} onSelect={(e) => e && setLayoutName(e)}>
                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                        Select Layout
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {layoutKeys.map((l) => (
                            <Dropdown.Item key={l} eventKey={l}>
                                {l}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <div className="pe-4">
                    Edge width
                    <input type="range" className="form-range" min="0" max="25" id="connectionsEdgeWidthSlider"
                           style={{border: 'none'}} defaultValue="10"/>
                </div>
                <div style={{lineHeight: 1, paddingRight: '1rem', paddingTop: '5px'}}
                     className="d-none d-lg-flex">
                    <Form.Check type="checkbox"
                                id="correlationCheckbox"
                                label={<span>Show<br/>correlations</span>}
                                checked={showCorrelation}
                                onChange={(e) => setShowCorrelation(e.target.checked)}
                    />
                </div>
            </div>
            <ErrorBoundary>
                <VerboseDiagram elements={elements} layoutName={layoutName} style={style}
                                showCorrelation={showCorrelation}
                                edgeWidthCoefficient={edgeWidthCoefficient}
                                nodesLimit={nodesLimit} latestNodeId={''}
                                reload={reload} loading={loading}
                                onLayoutApplied={() => console.log("Layout has been applied")}
                /></ErrorBoundary>
        </div>
    );
};

export default ConnectionsDiagram;