import {useQuery, useQueryClient} from 'react-query';
import QuestionDataService from '../services/question.service';
import {useEffect, useState, useCallback} from "react";
import {useCorrelation} from "./useCorrelation";

// todo: log every step here, still some mess with repeating statements (example: I like when a new concept I learn challenges my assumptions & woman -> age -> woman)
export function useConnections(
    org_id: string,
    id: string,
    connectionsStr: any,
    baseQuestion: any,
    custom_text: any,
    handleConnectionsData: Function,
    handleError: Function
) {
    const [stage, setStage] = useState<string>("Getting ready to send request...");
    const [connectionData, setConnectionData] = useState<any[]>([]);
    const [correlation, setCorrelation] = useState<number | undefined>(undefined);
    const [fetchReducedConnections, setFetchReducedConnections] = useState(false);
    const queryClient = useQueryClient();
    const timeout = 60000; // Timeout duration in milliseconds
    const handleCorrelation = useCallback((res: any) => {
        if (res) {
            setCorrelation(res.correlation);
        } else {
            console.log('No correlation data found');
        }
    }, []);

    useEffect(() => {
    }, [stage, connectionData, correlation, fetchReducedConnections]);

    const {isLoadingCorrelation, requestCorrelation} = useCorrelation(
        org_id, id, connectionsStr, baseQuestion, custom_text, handleCorrelation, handleError
    );

    const fetchConnections = useCallback(async () => {
        setStage("Fetching connections...");
        let _text = baseQuestion && !custom_text ? baseQuestion.canonical_text : custom_text;
        try {
            const data = await QuestionDataService.getConnections(org_id, _text, connectionsStr);
            return data;
        } catch (error) {
            handleError(error, 'connections');
            return null;
        }
    }, [org_id, baseQuestion, custom_text, connectionsStr, handleError]);

    const {
        isLoading: isLoadingConnections,
        data: connectionsData,
        error: connectionsError,
    } = useQuery(["query-fetch-connections", org_id, connectionsStr], fetchConnections, {
        enabled: !!connectionsStr,
        retry: 3,
        cacheTime: 1000 * 60 * 5, // 5 minutes cache time
        staleTime: 1000 * 60 * 1, // 1 minute stale time
        retryDelay: attempt => Math.min(1000 * 2 ** attempt, 30000),  // Exponential backoff with max 30 seconds
        onSuccess: async (data) => {
            if (data && Array.isArray(data)) {
                setConnectionData(data);
                if (data.length === 0) {
                    setStage("Done, no connections found!");
                    handleConnectionsData({
                        'statements': [
                            baseQuestion && !custom_text
                                ? baseQuestion.canonical_text
                                : custom_text,
                            connectionsStr
                        ],
                        'connections': []
                    });
                } else {
                    setFetchReducedConnections(true);
                    try {
                        await requestCorrelation();
                    } catch (error) {
                        handleError(error);
                    }
                }
            } else {
                handleError(new Error("Unexpected data structure"));
            }
        },
        onError: (error: unknown) => {
            if ((error as Error)?.message.includes('timeout')) {
                handleError(new Error('Request timed out'), 'connections');
            } else {
                handleError(error, 'connections');
            }
        },

    });

    useEffect(() => {
        // Only set the timeout if isLoadingConnections is true
        if (isLoadingConnections) {
            const timeout = 60000; // 60 seconds timeout
            const timer = setTimeout(() => {
                handleError(new Error('Request timed out'), 'connections');
            }, timeout);
            return () => clearTimeout(timer);
        }
    }, [isLoadingConnections, handleError]);

    const fetchReducedConnectionsFunction = useCallback(async () => {
        if (connectionData.length > 0) {
            setStage("Connections are found!");
            let _text = baseQuestion && !custom_text ? baseQuestion.canonical_text : custom_text;
            const chunks = [];
            for (let i = 0; i < connectionData.length; i += 10) {
                chunks.push(connectionData.slice(i, i + 10));
            }
            let reducedConnectionsChunks: any[] = [];
            setStage("Filtering and sorting connections...");

            for (let index = 0; index < chunks.length; index++) {
                const chunk = chunks[index];
                if (chunk && Array.isArray(chunk)) {
                    try {
                        // Implementing the timeout using Promise.race
                        const reducedConnectionsForChunk = await Promise.race([
                            QuestionDataService.reduceConnections(org_id, [_text, connectionsStr], chunk),
                            new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), timeout))
                        ]);

                        if (reducedConnectionsForChunk && Array.isArray(reducedConnectionsForChunk)) {
                            reducedConnectionsChunks.push(...reducedConnectionsForChunk);
                        }
                    } catch (error) {
                        handleError(new Error(`Timeout on chunk ${index + 1}`));
                    }
                }
                setStage(`Filtering and sorting connections (${index + 1} / ${chunks.length})...`);
            }

            reducedConnectionsChunks.sort((a, b) => {
                const factorA = Array.isArray(a?.linear_regression) && a.linear_regression.length >= 2
                    ? Math.sqrt(Math.abs(a.linear_regression[0]) * Math.abs(a.linear_regression[1]))
                    : 0; // Default to 0 or some other safe value
                const factorB = Array.isArray(b?.linear_regression) && b.linear_regression.length >= 2
                    ? Math.sqrt(Math.abs(b.linear_regression[0]) * Math.abs(b.linear_regression[1]))
                    : 0; // Default to 0 or some other safe value
                return factorB - factorA; // for descending order
            });
            return reducedConnectionsChunks.slice(0, 20);
        }
        return [];
    }, [org_id, baseQuestion, custom_text, connectionsStr, connectionData]);

    const {
        isLoading: isLoadingReducedConnections,
        data: reducedConnectionsData,
        error: reducedConnectionsError,
    } = useQuery(
        ["query-fetch-reduced-connections", org_id, connectionsStr, connectionData.length],
        fetchReducedConnectionsFunction,
        {
            enabled: fetchReducedConnections && connectionData.length > 0,
            retry: 3,
            cacheTime: 1000 * 60 * 5, // Adjust cache time to 5 minutes
        }
    );

    useEffect(() => {
        queryClient.invalidateQueries(["query-fetch-connections", "query-fetch-reduced-connections"]);
        setStage("searching for connections");
        setConnectionData([]);
        setCorrelation(undefined);
        setFetchReducedConnections(false);
    }, [connectionsStr, queryClient]);

    useEffect(() => {
        let inputBlock = document.getElementById('connectionsErrorBlock');
        if (inputBlock && isLoadingConnections) inputBlock.innerHTML = '';
    }, [isLoadingConnections]);

    useEffect(() => {
        if (connectionsError) handleError(connectionsError, 'connections');
        if (reducedConnectionsError) handleError(reducedConnectionsError, 'connections');
        if (
            !isLoadingConnections &&
            !isLoadingReducedConnections &&
            !isLoadingCorrelation &&
            connectionsData !== undefined &&
            reducedConnectionsData !== undefined &&
            correlation !== undefined
        ) {
            setStage("Done!");
            handleConnectionsData({
                'statements': [
                    baseQuestion && !custom_text ? baseQuestion.canonical_text : custom_text,
                    connectionsStr
                ],
                'correlation': correlation,
                'connections': reducedConnectionsData
            });
        }
    }, [
        connectionsData,
        reducedConnectionsData,
        correlation,
        handleConnectionsData,
        handleError
    ]);

    return {
        isLoadingConnections: isLoadingConnections || isLoadingReducedConnections || isLoadingCorrelation,
        fetchConnections,
        connectionsStage: stage
    };
}