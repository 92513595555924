// MetaTags.tsx
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import ReactGA from "react-ga4";
import {useLocation, useSearchParams} from "react-router-dom";

interface MetaTagsProps {
    description?: string;
    shareUrl?: string;
}

const UI_STRINGS = require('../../common/ui-strings.json')

const MetaTags: React.FC<MetaTagsProps> = ({description, shareUrl}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let queryParamSearchStrString = searchParams.get("search") || '';
    let queryParamSearchStr = decodeURIComponent(queryParamSearchStrString);

    const [locationKey, setLocationKey] = useState<string>('');
    const location = useLocation();
    const [docTitle, setDocTitle] = useState<string>(UI_STRINGS.metaTags.titles.default);
    // todo: move GA4 to a context or at least a hook
    useEffect(() => {
        let myTitle = '';
        let page = window.location.pathname.split('/')[1];
        let matchesTextAsQuestion = location.pathname.match(/^\/questions\/ut(?:\?text=(.*))?$/);
        let matchesQuestion = location.pathname.match(/^\/questions\/(?:([^\/]*))?$/);
        let matchesSearchPage = location.search.match(/^\?search=(.*?)(&|$)/);

        if (matchesTextAsQuestion) {
            if (location.state && location.state.custom_text) {
                myTitle = 'TextAsQuestion: ' + location.state.custom_text;
            } else if (matchesTextAsQuestion[1]) {
                myTitle = "TextAsQuestion Direct URL: " + matchesTextAsQuestion[1];
            }
        } else if (matchesQuestion) {
            if (location.state && location.state.originalSearch && location.state.originalSearch.originalSearchString) {
                myTitle = 'Question: ' + location.state.originalSearch.originalSearchString;
            } else if (matchesQuestion[1]) {
                myTitle = "Question Direct URL: " + matchesQuestion[1];
            }
        } else if (matchesSearchPage) {
            myTitle = 'Search Page: Results for ' + matchesSearchPage[1];
        } else {
            myTitle = "Home: Blank Search Page";
        }

        ReactGA.send({hitType: 'pageview', page: page, title: myTitle});
    }, [location]);

    useEffect(() => {
        console.log(location)
        let locationSecondLevel = locationKey ? locationKey : location.pathname.split('/')[1];
        console.log(locationSecondLevel)
        console.log("searchParams: ", queryParamSearchStr)
        if (queryParamSearchStr) {
            setDocTitle(UI_STRINGS.metaTags.titles['searchResults'] + ': ' + queryParamSearchStr)
        } else if (locationSecondLevel) {
            let str1 = (UI_STRINGS.metaTags.titles[locationSecondLevel])
            let str2 = ''
            if (location.state.originalSearch.originalSearchString) {
                str2 = location.state.originalSearch.originalSearchString
            }
            setDocTitle(str1 + ' \'' + str2 + '\'')
        }
    }, [location]);

    return (
        <Helmet>
            <title>{docTitle}</title>
            <meta property="og:title"
                  content={docTitle}/>
            {description && (
                <>
                    <meta property="og:description" content={description}/>
                </>
            )}
            {shareUrl && (
                <>
                    <meta property="og:url" content={shareUrl}/>
                </>
            )}
        </Helmet>
    );
};

export default MetaTags;