import React, {createContext, useState, useEffect, useContext, ReactNode} from 'react';
import {Amplify} from 'aws-amplify';
import {
    getCurrentUser,
    signOut as amplifySignOut,
    fetchUserAttributes as amplifyFetchUserAttributes
} from 'aws-amplify/auth';
import config from '../../amplifyconfiguration.json';
import ReactGA from "react-ga4";
import UserContextType from "../../types/userContext.type";
import {Hub} from "aws-amplify/utils";
import {useAuthenticator} from "@aws-amplify/ui-react";

Amplify.configure(config);

type AuthUser = {
    userId: string;
    email: string;
    // Define other user properties here based on what `getCurrentUser` returns
    // For example: username: string; email: string; etc.
};

type UserAttributes = {
    // Define user attribute properties here based on what `fetchUserAttributes` returns
    // For example: email: string; name: string; etc.
};


const defaultUserContext: UserContextType = {
    authenticatedUser: null,
    authModalShow: null,
    setAuthModalShow: () => {
    },
    afterSignIn: () => {
    },
    onSignInClick: () => {
    },
    onSignUpClick: () => {
    },
    requireAuth: () => {
    },
    signOut: async () => {
    },
};

const UserContext = createContext<UserContextType>(defaultUserContext);

interface UserProviderProps {
    children: ReactNode;
}

const convertToAuthUser = (user: any): AuthUser => {
    return {
        userId: user.userId,
        email: user.signInDetails.loginId,
        // Add other necessary properties mappings here
    };
};

export const UserProvider = ({children}: UserProviderProps) => {
    const [authenticatedUser, setAuthenticatedUser] = useState<AuthUser | null>(null);
    const [userAttributes, setUserAttributes] = useState<UserAttributes | null>(null);
    const [authModalShow, setAuthModalShow] = useState<string | null>(null);
    const {toSignIn, toSignUp} = useAuthenticator();

    const currentAuthenticatedUser = async () => {
        try {
            const result = await getCurrentUser();
            const authUser = convertToAuthUser(result);
            setAuthenticatedUser(authUser);
            fetchUserAttributes(authUser);
        } catch (error) {
            // Handle error
        }
    };

    const afterSignIn = () => {
        setAuthModalShow(null);
        currentAuthenticatedUser();

        ReactGA.send({
            hitType: 'event',
            eventCategory: 'Auth',
            eventAction: 'User logged in'
        });
    };

    const onSignUpClick = () => {
        toSignUp();
        ReactGA.send({
            hitType: 'event',
            eventCategory: 'Auth',
            eventAction: 'User clicked sign up button'
        });

        setAuthModalShow("signUpClick");

    };

    function onSignInClick() {
        toSignIn();
        ReactGA.send({
                hitType: 'event',
                eventCategory: 'Auth',
                eventAction: 'User clicked login button'
            }
        );
        setAuthModalShow("signInClick");

    }


    const requireAuth = () => {
        setAuthModalShow("signUp");
        toSignUp();
        ReactGA.send({
            hitType: 'event',
            eventCategory: 'Auth',
            eventAction: 'App requested authorization from user',
            nonInteraction: true
        });
    };
    useEffect(() => {
        currentAuthenticatedUser();
    }, []);

    const fetchUserAttributes = async (user: AuthUser) => {
        try {
            const result = await amplifyFetchUserAttributes();
            setUserAttributes(result as UserAttributes);
        } catch (error) {
            console.log('Error fetching user attributes: ', error);
        }
    };
    useEffect(() => {
        if (authenticatedUser) {
            ReactGA.set({user_id: authenticatedUser.userId});
        } else {
            ReactGA.set({user_id: null});
        }
    }, [authenticatedUser]);

    const signOut = async () => {
        localStorage.removeItem('userEmail');
        setAuthenticatedUser(null);
        try {
            await amplifySignOut().then(() => {
                ReactGA.send({
                        hitType: 'event',
                        eventCategory: 'Auth',
                        eventAction: 'User signed out'
                    }
                )
            });
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    };

    useEffect(() => {
        console.log("Fetching current authenticated user...");
        currentAuthenticatedUser();
    }, []);

    useEffect(() => {
        console.log("Authenticated user updated:", authenticatedUser);
    }, [authenticatedUser]);
    // todo!!!
    const listener = (data: any) => {
        console.log("Amplify Hub listener triggered:", data.payload.event);
        switch (data.payload.event) {
            case 'signedIn':
                console.log('User has signed in successfully.');
                afterSignIn();
                break;
            case 'signedOut':
                console.log('User has signed out successfully.');
                break;
            case 'tokenRefresh':
                console.log('Auth tokens have been refreshed.');
                break;
            case 'tokenRefresh_failure':
                console.log('Failure while refreshing auth tokens.');
                break;
            case 'signInWithRedirect':
                console.log('SignInWithRedirect API has resolved successfully.');
                break;
            case 'signInWithRedirect_failure':
                console.log('Failure while trying to resolve SignInWithRedirect API.');
                break;
            case 'customOAuthState':
                console.log('Custom state returned from CognitoHosted UI');
                break;
        }
    };


    useEffect(() => {
        const hubListenerCancelToken = Hub.listen('auth', listener);
        console.log("Props show::: ", authModalShow);

        return () => {
            hubListenerCancelToken();
        };
    }, [authModalShow]);
    useEffect(() => {
        if (authenticatedUser !== null && authenticatedUser.email) {
            localStorage.setItem('userEmail', authenticatedUser.email);
        }
    }, [authenticatedUser])

    return (
        <UserContext.Provider value={{
            authenticatedUser,
            authModalShow,
            setAuthModalShow,
            afterSignIn,
            onSignUpClick,
            onSignInClick,
            requireAuth,
            signOut: async () => {
                await amplifySignOut();
                setAuthenticatedUser(null);
            }
        }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);