import axios from "axios";
import UiConfig from "./ui-config"
import AppSettings from "./app-settings";

const currentUrl = window.location.href;
// const is_dev = currentUrl.search("localhost") >= 0;
const is_dev = AppSettings.useDevBackEnd;
let baseUrl;
let xApiKey = process.env.REACT_APP_XAPIKEY;


is_dev ? baseUrl = "https://53apljnk61.execute-api.us-east-2.amazonaws.com/stage" : baseUrl = "https://api.personalitymap.io"
is_dev ? xApiKey = "dev-" + xApiKey : xApiKey = '' + xApiKey
const apiClient = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-type": "application/json",
        "x-api-key": xApiKey,

    },
    timeout: 30000, // 30 seconds timeout
});
export default apiClient;
