import {Authenticator} from "@aws-amplify/ui-react";
import {Modal} from "react-bootstrap";
import {I18n} from 'aws-amplify/utils';
import {accessSearchDetailsPrompt, defaultHeader, termsSignUpFooter} from "../UIElements";
import {useUser} from "../../context/UserProvider/UserProvider";

I18n.setLanguage('en');
I18n.putVocabularies({
    en: {
        'Sign In': 'Log In',
    },
});

const AuthModal = () => {
    const {authModalShow, setAuthModalShow} = useUser();

    const handleClose = () => {
        setAuthModalShow(null);
    };

    const getComponents = () => {
        if (authModalShow) {
            const reason = authModalShow.endsWith("Click") ? "click" : "auto";
            const header = reason === "auto" ? accessSearchDetailsPrompt : defaultHeader;
            const footer = termsSignUpFooter;
            return {...header, ...footer};
        }
        return {};
    };

    return (
        <Modal
            centered
            backdrop="static"
            className="authModal"
            size={"lg"}
            show={!!authModalShow}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body className={"p-0"}>
                {!!authModalShow && (
                    <Authenticator
                        signUpAttributes={['email']}
                        formFields={{
                            signUp: {
                                email: {order: 1},
                                password: {order: 2},
                                confirm_password: {order: 3},
                            },
                        }}
                        components={getComponents()}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="pb-5 pt-3"></div>
            </Modal.Footer>
        </Modal>
    );
};

export default AuthModal;